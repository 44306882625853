import React, {useEffect, useState} from "react";
import Datepicker from 'react-datepicker';
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";

require('react-datepicker/dist/react-datepicker.css')

const postAttendance = (date, qr, type, time) => {
    //TODO add: https:// to the url
    let url = 'https://childcare24app.eetaa.gr/php/req.php?action=parousianew&domi=94695&date=' + date + '&time=' + time + '&barcode=' + qr + '&type=' + type + '&user=8539&ispending=1';
    // console.log(url)

    fetch(url, {
        method: 'GET'
    })
        .then((r) => {
            console.log(r)
        })
        .catch((e) => {
            console.log('error : ' + e)
        });
}
const dateIntToStr = (date_in) => {
    let date = new Date(date_in)
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}
const getStudents = async () => {
    return fetch('https://childcare23app.eetaa.gr/php/req.php?action=barcodes&domi=94695', {
        method: 'GET'
    })
}

function ScanStudents() {
    const [date, setDate] = useState(Date.now());
    const [number, setNumber] = useState(0);
    const [scannedNames, setScannedNames] = useState([]);
    useEffect(() => {
        //postAttendance(date)
        setNumber(parseInt(JSON.parse(localStorage.getItem('studentsInSchool-' + dateIntToStr(date)))) || 0);
        getStudents().then((response) => {
            return response.json()
        })
            .then((data) => {
                // console.log(data)
                localStorage.setItem('students', JSON.stringify(data));
            })
            .catch((e) => {
                console.log('had an error: ' + e + '\n using old data for qr codes')
            })
    }, [date])

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };
    const submitIn = (e) => {
        e.preventDefault();
        const student_list = JSON.parse(localStorage.getItem('students'));
        let scanned = parseInt(JSON.parse(localStorage.getItem('studentsInSchool-' + dateIntToStr(date))));
        let l = student_list.length;
        if (student_list) {
            let i = 0;
            if (scanned && scanned > 0) {
                i = scanned;
            }
            const minute = 60000;
            const startTime = new Date();
            startTime.setHours(7);
            startTime.setMinutes(20);
            for (i; i < number && i < l; i++) {
                let time = new Date(startTime.getTime() + (Math.random() + 0.4) * i * minute).toTimeString().slice(0, 8);
                // console.log(i+' : ' + time)
                let qr = student_list[i].Barcode
                postAttendance(dateIntToStr(date), qr, 0, time)
                let name= student_list[i].LastName + ' ' + student_list[i].FirstName
                setScannedNames([...scannedNames, name])
            }
            if (number > scanned || !scanned) {
                localStorage.setItem('studentsInSchool-' + dateIntToStr(date), JSON.stringify(number));
                if (number > l) {
                    localStorage.setItem('studentsInSchool-' + dateIntToStr(date), JSON.stringify(l));
                }
            }
        }

    }
    const submitOut = (e) => {
        e.preventDefault();
        const student_list = JSON.parse(localStorage.getItem('students'));
        let scanned = parseInt(JSON.parse(localStorage.getItem('studentsInSchool-' + dateIntToStr(date))));
        let l = student_list.length;
        if (student_list) {
            let i = l;
            if (scanned && scanned > 0) {
                i = scanned;
            }
            const minute = 60000;
            const startTime = new Date();
            startTime.setHours(14);
            startTime.setMinutes(10);
            for (i; i > number ; i--) {
                let time = new Date(startTime.getTime() + (Math.random() + 0.4) * i * minute).toTimeString().slice(0, 8);
                // console.log(i+' : ' + time)
                let qr = student_list[l-i].Barcode
                postAttendance(dateIntToStr(date), qr, 1, time)
                let name= student_list[l-i].LastName + ' ' + student_list[l-i].FirstName
                setScannedNames([...scannedNames, name])
            }
            if (number < scanned || !scanned) {
                localStorage.setItem('studentsInSchool-' + dateIntToStr(date), JSON.stringify(number));
                if (number > l) {
                    localStorage.setItem('studentsInSchool-' + dateIntToStr(date), JSON.stringify(l));
                }
            }
        }

    }
    return (
        <>
            <Form.Label>Date:</Form.Label><br></br>
            <Datepicker className={'form-control text-center'}
                        maxDate={Date.now()}
                        selected={date}
                        dateFormat={'dd-MM-yyyy'}
                        onChange={(e) => {
                            setDate(Date.parse(e))
                        }}
                        filterDate={isWeekday}
                        placeholderText="Select a weekday">
            </Datepicker>
            <Form.Group className="my-3 mx-5 px-5" controlId="UserPrwi">
                <Form.Label>Number of students inside the school:</Form.Label>
                <Form.Control className={'text-center'} type="number" min={0} value={number} onChange={(e) => {
                    setNumber(e.target.value);
                }} required/>
            </Form.Group>

            <Button className="mx-3 btn-light" onClick={submitIn}>IN</Button>
            <Button className="mx-3 btn-light" onClick={submitOut}>OUT</Button>
            <div className={"mt-3"}>
                Students inside the school: {JSON.parse(localStorage.getItem('studentsInSchool-' + dateIntToStr(date))) || 0}
            </div>
            {/*<div className={'row mt-5'}>*/}
            {/*    <div className={'col-12'}>*/}
            {/*            {scannedNames.map((single)=>{return <span key={single}>{single}</span>})}*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    )


}

export default ScanStudents